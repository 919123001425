:root {
  --my-theme: rgb(0, 65, 115);
  --my-theme-hover: rgb(0, 54, 94);
  --my-theme-hsla: hsla(206, 100%, 22.5%, 0.1);
}

#root {
  color: var(--my-theme);
  font-family: MuseoSans, sans-serif;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}

.label {
  font-weight: 700;
  letter-spacing: 0.0125rem;
  font-size: 2.0625rem;
  line-height: 2.5rem;
}

h6 {
  font-weight: bold;
}

.title-choice {
  padding-right: 10%;
  padding-left: 10%;
}

.app-container {
  display: flex;
  flex-direction: column;
  padding-right: 10%;
  padding-left: 10%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.form-container .item {
  display: flex;
  flex-direction: column;
}

.form-container .item-names, .item-street{
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.form-container .item-names div{
  flex: 1;
}

.form-container .item-street div{
  flex: 1;
}

.form-container .item-street .street{
  flex-grow: 4;
}

.form-container .item-street .city{
  flex-grow: 3;
}

.form-container label {
  display: block;
}

.form-control {
  border: 1px solid var(--my-theme);
  background-color: var(--my-theme-hsla);
}

.form-control:focus {
  border-color: var(--my-theme);
  box-shadow: 0 0 0 0.2rem var(--my-theme-hsla);
}

.form-select {
  border: 1px solid var(--my-theme);
  background-color: var(--my-theme-hsla);
}

.form-select:focus {
  border-color: var(--my-theme);
  box-shadow: 0 0 0 0.2rem var(--my-theme-hsla);
}

.form-check-input {
  border: 1px solid var(--my-theme);
  background-color: var(--my-theme-hsla);
}

.form-check-input:checked {
  background-color: var(--my-theme);
  border-color: var(--my-theme);
}

.form-check-input:focus {
  border-color: var(--my-theme);
  box-shadow: 0 0 0 0.2rem var(--my-theme-hsla);
}

.button {
  color: #fff;
  cursor: pointer;
  background-color: var(--my-theme);
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--my-theme);
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
}

.button:hover {
  background-color: var(--my-theme-hover);
  border: 1px solid var(--my-theme-hover);
}

.buttonDisabled, .buttonDisabled:hover {
  color: #666666;
  background-color: #cccccc;
  cursor: default;
  border: 1px solid #666666;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.dropContainer p {
  text-align: center;
}

.dropContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 200px;
  border: 2px dashed #4aa1f3;
}

.acceptedFile {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.acceptedFiles {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fileRemove  {
  cursor: pointer;
  color: red;
}

p, label, ul {
  color: black;
}

.item-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 650px;
  margin-bottom: 1rem;
}

.item-message a {
  color: var(--my-theme);
  text-decoration: none;
}

.item-message a:hover {
  color: var(--my-theme-hover);
}

.item a {
  color: var(--my-theme);
}

.item a:hover {
  color: var(--my-theme-hover);
}

.acceptedFileName {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  gap: 1rem;
}

.forbiddenFile {
  color: rgb(220, 53, 69);
}

@media screen and (max-width: 800px) {
  .title-choice {
    text-align: center;
  }

  .form-container {
    justify-content: center;
  }

  .form-container .item h4, .form-container .item h5{
    text-align: center;
  }

  .form-container .item-names, .item-street {
    flex-direction: column;
  }

  .MuiStepLabel-labelContainer {
    display: none;
  }

  .acceptedFile {
    flex-direction: column;
  }
}